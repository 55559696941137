var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Ajax" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAjax) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vue-autosuggest", {
        ref: "autocomplete",
        attrs: {
          suggestions: _vm.suggestions,
          "input-props": _vm.inputProps,
          "section-configs": _vm.sectionConfigs,
          "render-suggestion": _vm.renderSuggestion,
          "get-suggestion-value": _vm.getSuggestionValue
        },
        on: { input: _vm.fetchResults },
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      }),
      _c("b-card-text", { staticClass: "mt-1" }, [
        _vm._v(" Selected element (object): ")
      ]),
      _c("b-card", { staticClass: "border", attrs: { "no-body": "" } }, [
        _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.selected, null, 4)))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }