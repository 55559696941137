var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Section" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSection) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vue-autosuggest", {
        attrs: {
          suggestions: _vm.suggestions,
          "input-props": _vm.inputProps,
          "section-configs": _vm.sectionConfigs,
          "render-suggestion": _vm.renderSuggestion,
          limit: 10
        },
        on: { input: _vm.fetchResults },
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }