var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vue-autosuggest", {
        attrs: {
          suggestions: _vm.filteredOptions,
          limit: 10,
          "input-props": {
            id: "autosuggest__input",
            class: "form-control",
            placeholder: "Do you feel lucky?"
          }
        },
        on: { input: _vm.onInputChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var suggestion = ref.suggestion
              return [
                _c("span", { staticClass: "my-suggestion-item" }, [
                  _vm._v(_vm._s(suggestion.item.name))
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }