var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Slot" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSlot) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vue-autosuggest", {
        attrs: {
          suggestions: _vm.filteredOptions,
          "input-props": _vm.inputProps,
          "on-selected": _vm.onSelected,
          limit: 10
        },
        on: { input: _vm.onInputChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var suggestion = ref.suggestion
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("b-avatar", { attrs: { src: suggestion.item.img } }),
                    _c(
                      "div",
                      { staticClass: "detail ml-50" },
                      [
                        _c("b-card-text", { staticClass: "mb-0" }, [
                          _vm._v(" " + _vm._s(suggestion.item.name) + " ")
                        ]),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(" " + _vm._s(suggestion.item.time) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }